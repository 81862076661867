<template>
    <div class="pages">
        <pageHeader></pageHeader>
        <div class="box">
            <div class="main">
                <div class="video-box">
                    <div class="video-main">
                        <div class="video-main-top">
                            <!-- <div class="video" id="J_prismPlayer"></div> -->
                            <!-- <AliplayerVue :vid="payDetails.videoId" :playAuth="payDetails.playAuth"></AliplayerVue> -->
                            <vue-aliplayer-v2 :key="playLoad" source="" ref="VueAliplayerV2" :options="playOptions" />
                            <div class="video-chapter">
                                <div class="chapter-title">
                                    <span>目录({{ infos.chapters.length }})</span>
                                    <img src="../../static/icon/chapter-icon1.png">
                                </div>
                                <div class="chapter-lists">
                                    <div class="chapter-item" v-for="(item, index) in infos.chapters" :key="index">
                                        <span class="chapter-item-title">第{{ index+1 }}章
                                            <span>{{ item.title }}</span>
                                        </span>
                                        <div class="chapter-item-el"
                                            :class="el.library.id === chapterIdx ? 'chapter-item-el-active' : ''"
                                            v-for="(el, elx) in item.details" :key="elx"
                                            @click="changeChapter(el.library.id)">
                                            <img v-if="el.library.id === chapterIdx"
                                                src="../../static/icon/chapter-icon.png">
                                            <span v-else class="chapter-item-el-num">{{ elx + 1 }}</span>
                                            <span class="chapter-item-el-title">{{ el.library.title }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="video-main-footer">
                            <div class="video-main-footer-info">
                                <span class="title">{{ infos.title }}</span>
                                <span class="no-price" v-if="infos.price === 0">免费</span>
                                <span class="price" v-else>￥{{ infos.price }}</span>
                                <span class="sign-num">{{ infos.bCount }}人 报名</span>
                            </div>
                            <div class="video-main-footer-right" v-if="false">
                                <div class="btn sgin-no">加入订阅</div>
                                <div class="btn sgin-yes">
                                    <img src="../../static/icon/sgin-icon.png">
                                    已报名
                                </div>
                                <img src="../../static/icon/collection-no.png">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="college-info">
                    <div class="college-info-tab">
                        <div class="college-info-tab-item college-info-tab-item-active">
                            课程介绍
                        </div>
                    </div>
                    <div class="rich-text" v-html="infos.content"></div>
                </div>
            </div>
        </div>
        <pageFooter></pageFooter>
    </div>
</template>

<script>
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import VueAliplayerV2 from "vue-aliplayer-v2";
import { getCollegeDetails, getPlayDetail } from '@/api/college.js'

export default {
    name: "college",
    components: {
        pageHeader,
        pageFooter,
        VueAliplayerV2
    },
    data() {
        return {
            playLoad:1,
            id: '',
            infos: {
                chapters: []
            },//课程详情
            chapterIdx: null,//当前播放的小节的id
            payDetails: {},//播放详细信息
            player: null,
            playOptions:{
                width: '830px',
                height: '475px',
                autoplay: false
            }
        }
    },
    created() {
        this.id = this.$route.query.id
        this.getDetails()
    },
    mounted() {
        // 初始化播放器
        // this.player = new Aliplayer({
        //     "id": "J_prismPlayer", // 播放器容器id
        //     "autoplay": false, // 是否自动播放
        //     "controlBarVisibility": "hover", // 控制栏的显示方式
        //     "useH5Prism": true, // 是否使用H5播放器
        //     "vid": this.payDetails.videoId, // 视频ID
        //     "playauth": this.payDetails.playAuth, // 播放凭证
        //     // 其他播放器配置...
        // }, function (player) {
        //     console.log('播放器创建好了。',player)
        // });
    },
    methods: {
        /**
         * 获取课程详情
         */
        getDetails() {
            getCollegeDetails(this.id).then(res => {
                this.infos = res
                if (this.infos.chapters.length > 0 && this.infos.chapters[0].details.length > 0) {
                    this.chapterIdx = this.infos.chapters[0].details[0].library.id
                    this.getPlayDetail(this.chapterIdx)
                }
            })
        },
        /**
         * 切换播放内容
         */
        changeChapter(id) {
            this.chapterIdx = id
            this.getPlayDetail(this.chapterIdx)
        },
        /**
         * 获取播放详情
         */
        getPlayDetail(id) {
            getPlayDetail(id).then(res => {
                this.payDetails = res
                this.playOptions.vid = this.payDetails.videoId
                this.playOptions.playauth = this.payDetails.playAuth
                this.playOptions.cover = this.payDetails.cover
                this.playLoad += 1
            })
        }
    },
    // beforeDestroy(){
    //     this.$refs.VueAliplayerV2.player.pause()
    //     this.$refs.VueAliplayerV2.player.dispose()
    // }
}
</script>

<style scoped>
.pages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 120px);
    background-color: #F7F7F8;
}

.main {
    width: 100%;
    min-height: calc(100vh - 168px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-box {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video-main {
    width: 1200px;
    margin-top: 24px;
}

.video-main .video-main-top {
    width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.video-main .video-main-top .video {
    width: 830px;
    height: 475px;
    background-color: bisque;
}

.video-main .video-main-top .video-chapter {
    width: 346px;
    height: 475px;
    background-color: #F3F4F7;
    padding: 16px;
}

.chapter-title {
    color: #06121E;
    font-size: 16px;
}
.chapter-title img{
    width: 15px;
    margin-left: 8px;
}

.chapter-lists {
    margin-top: 24px;
    height: 410px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.chapter-item {
    width: 290px;
    margin-top: 24px;
}

.chapter-item:first-child {
    margin-top: 0;
}

.chapter-item-title {
    width: 290px;
    color: #06121E;
    font-size: 16px;
    font-weight: bold;
}

.chapter-item-title span {
    margin-left: 12px;
}

.chapter-item-el {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: #06121E;
    font-size: 14px;
    cursor: pointer;
}

.chapter-item-el img {
    width: 12px;
    height: 12px;
    margin-top: 3px;
}

.chapter-item-el-active {
    color: #DD0722;
}

.chapter-item-el-num {}

.chapter-item-el-title {
    margin-left: 16px;
}

.chapter-item-el:last-child {
    margin-bottom: 24px;
}

.video-main .video-main-footer {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.video-main .video-main-footer .video-main-footer-info {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0;
}

.video-main .video-main-footer .video-main-footer-info .title {
    width: 100%;
    color: #06121E;
    font-size: 18px;
}

.video-main .video-main-footer .video-main-footer-info .no-price {
    margin-top: 22px;
    color: #424D57;
    font-size: 14px;
    width: 30px;
    height: 20px;
    background-color: #E7E8EB;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-main .video-main-footer .video-main-footer-info .price {
    margin-top: 22px;
    color: #E2450A;
    font-size: 16px;
}

.video-main .video-main-footer .video-main-footer-info .sign-num {
    margin-top: 22px;
    color: #63676D;
    font-size: 14px;
    margin-left: 18px;
}

.video-main-footer-right {
    display: flex;
    align-items: center;
    padding-right: 40px;
}

.video-main-footer-right .btn {
    width: 140px;
    height: 36px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.video-main-footer-right .sgin-no {
    background-color: #4181B8;
    color: #ffffff;
}

.video-main-footer-right .sgin-yes {
    background-color: #F0F2F5;
    color: #333333;
}

.video-main-footer-right .sgin-yes img {
    width: 13px;
    height: 8px;
    margin-right: 6px;
}

.video-main-footer-right img {
    width: 20px;
    height: 20px;
    margin-left: 24px;
}

.college-info {
    width: 1200px;
    padding: 0 28px;
    background-color: #ffffff;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 34px;
}

.college-info-tab {
    width: 1144px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.college-info-tab-item {
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.college-info-tab-item-active::after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 4px;
    background-color: #DD0722;
    bottom: 0;
    left: 0;
}

.rich-text {
    width: 1144px;
    margin-top: 19px;
    padding-bottom: 100px;
}
</style>
